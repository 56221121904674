var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner' || _vm.title === 'System Administrator'),expression:"title === 'Business Owner' || title === 'System Administrator'"}],attrs:{"to":"/all_restaurants"},on:{"click":_vm.clearLocalStorage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-hotel"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Restaurants")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner' || _vm.title === 'System Administrator'),expression:"title === 'Business Owner' || title === 'System Administrator'"}],attrs:{"to":"/orders_list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-shopping-cart"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Menu Orders")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],attrs:{"to":"/analytics"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-chart-area"}),_c('span',{staticClass:"menu-text"},[_vm._v("Analytics")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner' || _vm.title === 'System Administrator'),expression:"title === 'Business Owner' || title === 'System Administrator'"}],attrs:{"to":"/add_merchant_accounts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Merchant Accounts")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],attrs:{"to":"/users_list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-users"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("User List")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],attrs:{"to":"/owners_list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-1"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Owner List")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner' || _vm.title === 'System Administrator'),expression:"title === 'Business Owner' || title === 'System Administrator'"}],attrs:{"to":"/custom_notifications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa flaticon2-notification"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Notification Blast")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'System Administrator'),expression:"title === 'System Administrator'"}],attrs:{"to":"/system_settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("System Settings")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner'),expression:"title === 'Business Owner'"}],attrs:{"to":"/RestaurantDashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Restaurant Dashboard")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.title === 'Business Owner' || _vm.title === 'System Administrator'),expression:"title === 'Business Owner' || title === 'System Administrator'"}],attrs:{"to":"/order_details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return undefined}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }