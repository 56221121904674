<template>
  <ul class="menu-nav">
    <router-link
      v-show="title === 'System Administrator'"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-home"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'Business Owner' || title === 'System Administrator'"
      @click="clearLocalStorage"
      to="/all_restaurants"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-hotel">
            <span></span>
          </i>
          <span class="menu-text">Restaurants</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'Business Owner' || title === 'System Administrator'"
      to="/orders_list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-shopping-cart">
            <span></span>
          </i>
          <span class="menu-text">Menu Orders</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'System Administrator'"
      to="/analytics"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-chart-area"></i>
          <span class="menu-text">Analytics</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'Business Owner' || title === 'System Administrator'"
      to="/add_merchant_accounts"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-show="title === 'System Administrator'"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user">
            <span></span>
          </i>
          <span class="menu-text">Merchant Accounts</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'System Administrator'"
      to="/users_list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-users">
            <span></span>
          </i>
          <span class="menu-text">User List</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'System Administrator'"
      to="/owners_list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user-1">
            <span></span>
          </i>
          <span class="menu-text">Owner List</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'Business Owner' || title === 'System Administrator'"
      to="/custom_notifications"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <!--          <router-link-->
      <!--            v-show="title === 'Business Owner'"-->
      <!--            to="/custom_notifications"-->
      <!--            v-slot="{ href, navigate, isActive, isExactActive }"-->
      <!--          >-->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa flaticon2-notification">
            <span></span>
          </i>
          <span class="menu-text">Notification Blast</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'System Administrator'"
      to="/system_settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-cog"></i>
          <span class="menu-text">System Settings</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-show="title === 'Business Owner'"
      to="/RestaurantDashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Restaurant Dashboard</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-show="title === 'Business Owner' || title === 'System Administrator'"
      to="/order_details"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data() {
    return {
      currentUser: "",
      title: ""
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    clearLocalStorage() {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("restaurantDetails");
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.title = this.currentUser.roles[0].title;
  },
  mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.title = this.currentUser.roles[0].title;
  }
};
</script>
