<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: User Bar -->
    <div
      v-show="title === 'Business Owner'"
      class="position-relative notification-wrapper"
      @click="clickedBell"
    >
      <span
        v-if="notificationCount !== ''"
        class="badge badge-pill badge-primary notification-count"
      >
        {{ notificationCount }}
      </span>
      <i class="fas fa-bell"></i>
      <div v-if="showNotification" class="notification-dropdown">
        <div class="notification-header">
          <span class="heading">Notifications</span>
        </div>
        <div class="notification-body">
          <ul>
            <li class="text-center font-weight-bold text-heading">Today</li>
            <li
              class="unread-notification"
              v-for="(notification, index) in notificationList"
              :key="index"
            >
              <div>
                <router-link
                  to="/order_details"
                  class="d-flex align-items-start"
                >
                  <img
                    src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"
                    alt=""
                  />
                  <div>
                    <h5 class="mb-0 font-weight-bold text-orange">
                      {{ notification.user.name }}
                    </h5>
                    <p class="mb-0">
                      {{ notification.message }}
                    </p>
                  </div>
                </router-link>
              </div>
            </li>
            <!--            <li class="text-center font-weight-bold text-heading">Yesterday</li>-->
            <!--            <li class="read-notification">-->
            <!--              <div>-->
            <!--                <a href="" class="d-flex align-items-start">-->
            <!--                  <img-->
            <!--                    src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"-->
            <!--                    alt=""-->
            <!--                  />-->
            <!--                  <div>-->
            <!--                    <h5 class="mb-0 font-weight-bold text-orange">Abc</h5>-->
            <!--                    <p class="mb-0">-->
            <!--                      New Order Received.-->
            <!--                    </p>-->
            <!--                  </div>-->
            <!--                </a>-->
            <!--              </div>-->
            <!--            </li>-->
          </ul>
        </div>
        <div class="notification-footer notification-header">
          <router-link to="/notificationslist" class="text-orange">
            <span>See All</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="user-menu-wrap">
      <div>
        <a class="mini-photo-wrapper" @click="profileMenu()">
          <img :src="updatedUser.avatar_src" class="" alt="profile-img" />
          <!--          <img :src="'profile.png'" class="" alt="profile-img" />-->
          <!-- <i class="fas fa-angle-down"></i> -->
        </a>
      </div>
      <div class="menu-container" v-if="menuProfile">
        <ul class="user-menu">
          <li class="user-menu__item">
            <router-link to="/profile" class="user-menu-link">
              <p class="mb-0">Profile</p>
            </router-link>
          </li>
          <li @click="onLogout">
            <a class="user-menu-link cursor-pointer">
              <p class="mb-0">Sign out</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import axios from "axios";
import i18nService from "@/core/services/i18n.service.js";
// import Vue from "vue";
// import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import helpers from "../../../helpers";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      menuProfile: false,
      languages: i18nService.languages,
      showNotification: false,
      notificationList: [],
      notificationCount: "",
      title: "",
      restaurantUUID: "",
      profileImage: ""
    };
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    clickedBell() {
      this.showNotification = !this.showNotification;
    },
    profileMenu() {
      this.menuProfile = !this.menuProfile;
    },
    getAllNotifications() {
      axios
        .get(
          this.$path + "/admin/restaurant/notifications/" + this.restaurantUUID
        )
        .then(({ data }) => {
          this.notificationList = data.data.notifications;
          this.notificationCount = data.data.count;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    closeMenu() {
      this.menuProfile = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    },
    getUserInfo() {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.title = currentUser.roles[0].title;
      this.profileImage = currentUser.avatar_src;
    }
  },
  computed: {
    ...mapGetters({ updatedUser: "updatedUser" }),

    // currentUser
    userImage() {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      return currentUser.avatar_src;
      // return this.$store.auth.user.data.avatar_src;
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.title = currentUser.roles[0].title;
    this.profileImage = currentUser.avatar_src;
    if (this.title === "Business Owner") {
      this.restaurantUUID = JSON.parse(
        localStorage.getItem("currentUser")
      ).restaurant.uuid;
      this.getAllNotifications();
      this.$channel.bind("PendingOrderEvent", function(data) {
        if (data.activity_type === "order_place") {
          this.notificationCount = data.count;
          let URL;
          URL = this.$path + "/admin/restaurant/order/print/" + data.order.uuid;
          axios
            .get(URL)
            .then(() => {
              this.$bvToast.toast("print order successfully", {
                title: "Success",
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center"
              });
            })
            .catch(err => {
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    }
  }

  // beforeDestroy() {
  //   document.removeEventListener("click", this.close);
  // }
};
</script>
<style scoped>
.topbar {
  align-items: center;
}
.text-heading {
  color: #747f8b;
}
.notification-wrapper {
  margin-right: 30px;
}
.notification-wrapper i {
  font-size: 24px;
  cursor: pointer;
}
.notification-wrapper .badge-primary {
  background-color: #fd683e;
}
.notification-wrapper .notification-count {
  position: absolute;
  top: -5px;
  right: -15px;
}
.notification-dropdown {
  position: absolute;
  /*display: none;*/
  z-index: 1;
  left: -179px;
  top: 46px;
  min-height: 10px;
  min-width: 10px;
  width: 280px;
}
.notification-wrapper .notification-header {
  background: #fff;
  padding: 15px;
  position: relative;
  text-align: center;
  color: #fd683e;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  -moz-border-image: -moz-linear-gradient(right, white, #cedae0, #cedae0, white)
    1 20%;
  -o-border-image: -o-linear-gradient(right, white, #cedae0, #cedae0, white) 1
    20%;
  border-image: linear-gradient(
      to right,
      white 0%,
      #cedae0 40%,
      #cedae0 60%,
      white 100%
    )
    1 20%;
  box-shadow: 0px 2px 10px -2px #cedae0;
}
.notification-body {
  max-height: 292px;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.notification-body li p {
  color: #9b9b9b;
}
.notification-body li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}
.notification-body li a {
  color: #333;
}
.notification-body li.unread-notification {
  background-color: #def2f7;
}
.notification-body li.unread-notification p {
  color: #333;
}
.notification-body li.read-notification {
  background-color: #fff;
}
.notification-body li img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.notification-footer.notification-header {
  border-radius: 0 0 10px 10px;
  border-bottom: 0;
}
</style>
