<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img alt="Logo" class="mobile-logo" :src="headerLogo" />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <div class="user-menu-wrap d-sm-block d-md-block d-lg-none">
        <div>
          <a class="mini-photo-wrapper" @click="profileMenu()">
            <img :src="'profile.png'" class="" alt="profile-img" />
            <!-- <i class="fas fa-angle-down"></i> -->
          </a>
        </div>
        <div class="menu-container" v-if="menuProfile">
          <ul class="user-menu" @click="closeMenu()">
            <li class="user-menu__item">
              <router-link to="/profile" class="user-menu-link">
                <p class="mb-0">Profile</p>
              </router-link>
            </li>
            <li @click="onLogout">
              <a class="user-menu-link cursor-pointer"
                ><p class="mb-0" @click="onLogout">Sign out</p></a
              >
            </li>
          </ul>
        </div>
      </div>
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="d-none btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <button
        class="d-none btn btn-hover-text-primary p-0 ml-2"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      >
        <span class="d-none svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
          <inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
      </button>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "KTHeaderMobile",
  data() {
    return {
      menuProfile: false
    };
  },
  methods: {
    profileMenu() {
      this.menuProfile = !this.menuProfile;
    },
    closeMenu() {
      this.menuProfile = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  components: {},
  mounted() {
    document.addEventListener("click", this.close);
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
